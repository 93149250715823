@import '../../styles/variables';
.pre-register-page-wrap {
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #d5d5d5;
  }
  .container {
    min-height: calc(100vh - 220px);
    width: 100%;
    padding-bottom: 40px;
    .ant-space {
      width: 100%;
    }
    .container-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      padding-top: 40px;
      row-gap: 40px;
      .top-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        cursor: pointer;
        .top-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 50px;
          background: #ffffff;
          border-radius: 10px;
          border: 1px solid $primary;
          font-size: 16px;
          font-weight: 400;
          color: $primary;
          a {
            color: $primary;
          }
        }
      }
      .register-title {
        font-size: 44px;
        font-weight: bold;
        color: #01142e;
        text-align: center;
      }
      .register-card {
        padding: 24px 24px;
        width: 818px;
        background: #f9f9f9;
        border-radius: 10px;
        overflow: hidden;
        .register-rule {
          .rule-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 30px;
            color: #01142e;
          }
          .register-text {
            font-size: 12px;
            line-height: 25px;
            color: #828282;
          }
        }
      }
      .form-wrap {
        width: 818px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        .from-item {
          display: flex;
          height: 60px;
          border: 1px solid #d6d6d6;
          border-radius: 10px;
          overflow: hidden;
          .item-left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 12px 0 35px;
            width: 250px;
            height: 100%;
            background: #f9f9f9;
            border-right: 1px solid #d6d6d6;
            font-size: 16px;
            font-weight: 400;
            color: #01142e;
            .required-text {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 19px;
              background: #ff0000;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .item-right {
            flex: 1;
            width: 100%;
            height: 100%;
            input,
            .ant-input-password {
              padding: 4px 30px;
              height: 100%;
              border: none;
              outline: none;
              box-shadow: none;
              font-size: 16px;
              font-weight: 400;
            }
            .ant-input-password {
              & > input {
                padding-left: 0;
              }
            }
            .ant-select {
              height: 100%;
              width: 100%;
              .ant-select-selector {
                height: 100%;
                width: 100%;
                border: none;
                box-shadow: none;
                font-size: 16px;
                font-weight: 400;
              }
            }
          }
        }
        .from-desc {
          padding-left: 274px;
          font-size: 16px;
          font-weight: 400;
          color: #b2b2b2;
        }
      }
      .register-success-card {
        padding: 24px 24px;
        width: 818px;
        color: #4caf50;
        border-radius: 10px;
        overflow: hidden;
        background-color: #edf7ee;
      }
      .register-success-title {
        font-size: 20px;
      }
    }
  }
}
