.open-membership-page {
  &-container {
    padding: 60px 0;
    min-height: calc(100vh - 100px - 200px);
    .vip-info-card {
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      padding: 0 44px 0 55px;
      width: 100%;
      height: 159px;
      background: #004495;
      border-radius: 10px;
      .img-wrap {
        width: 80px;
        height: 80px;
        background: #ffffff;
        border-radius: 50%;
        overflow: hidden;
      }
      .vip-info {
        margin: 0 24px;
        padding: 3px 0;
        flex: 1;
        width: 100%;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .vip-email {
          font-size: 28px;
          font-weight: 400;
          color: #ffffff;
        }
        .vip-time {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .vip-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 205px;
        height: 60px;
        background: #ffffff;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 400;
        color: #004495;
        cursor: pointer;
      }
    }
    .set-meal-card {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
      .title {
        font-size: 24px;
        font-weight: 400;
        color: #01142e;
      }
      .meal-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 42px;
        .meal-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 28px;
          width: calc((100% - 42px * 2) / 3);
          height: 229px;
          background: #ffffff;
          border: 1px solid #d6d6d6;
          border-radius: 10px;
          cursor: pointer;
          &.active,
          &:hover {
            border: 1px solid #ebc96a;
            background: #fcf7e6;
          }
          .title {
            text-align: center;
            font-size: 26px;
            font-weight: 400;
            color: #01142e;
          }
          .text {
            margin-top: 4px;
            text-align: center;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #01142e;
          }
          .unit {
            font-size: 14px;
            font-weight: normal;
            color: #dfb349;
          }
          .price {
            font-size: 24px;
            font-weight: bold;
            color: #dfb349;
          }
        }
      }
    }
    .meal-desc-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 12px;
      padding: 20px;
      width: 100%;
      // height: 127px;
      background: #f9f9f9;
      border-radius: 10px;
      font-weight: 400;
      .card-title {
        font-size: 16px;
        color: #01142e;
      }
      .special-title {
        padding: 4px 12px;
        border-radius: 4px;
        line-height: normal;
        font-size: 12px;
        color: #fff;
        background-color: #004495;
      }
    }
    .payment-mode-card {
      margin-top: 70px;
      .title {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: bold;
        color: #01142e;
      }
      .text {
        margin-bottom: 27px;
        font-size: 18px;
        font-weight: 400;
        color: #01142e;
      }
      .mode-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 30px;
        .mode-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc((100% - 30px * 3) / 4);
          height: 74px;
          background: #ffffff;
          border: 1px solid #d6d6d6;
          border-radius: 10px;
        }
        .offline-item {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 24px 20px;
          height: 168px;
          width: calc((100% - 30px * 3) / 4);
          background: #ffffff;
          border: 1px solid #d6d6d6;
          border-radius: 10px;
          .title {
            text-align: center;
            font-size: 20px;
            font-weight: 400;
            color: #004495;
          }
          .offline-item-info {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #01142e;
          }
        }
        .mode-item,
        .offline-item,
        .upload-item {
          cursor: pointer;
          &.active,
          &:hover {
            border: 1px solid #004495;
          }
        }
        .upload-item {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 20px;
          width: calc((1200px - 30px * 3) / 4);
          height: 168px;
          background: #ffffff;
          border: 1px solid #d6d6d6;
          border-radius: 10px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #bbbbbb;
        }
      }
    }
  }
}
