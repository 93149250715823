.video-play-page {
  .video-play-container {
    min-height: calc(100vh - 100px);
    padding: 70px 0 0;
    background: #f8f8f8;
    .container-wrap {
      display: flex;
      align-items: center;
      column-gap: 10px;
      height: 730px;
      .container-left {
        flex: 1;
        width: 100%;
        height: 100%;
        .left-video {
          height: 622px;
          // background: #000000;
        }
        .left-footer {
          padding: 0 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          row-gap: 15px;
          height: 108px;
          background: #ffffff;
          .footer-header {
            display: flex;
            align-items: center;
            column-gap: 10px;
            .footer-tag {
              width: 49px;
              height: 23px;
              line-height: 23px;
              text-align: center;
              background: rgba(0, 68, 149, 0.2);
              border-radius: 12px;
              font-size: 14px;
              font-weight: 400;
              color: #004495;
            }
            .footer-title {
              font-size: 18px;
              color: #01142e;
            }
          }
          .footer-desc {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
          }
        }
      }
      .container-right {
        align-self: flex-start;
        padding: 16px 0 16px 20px;
        width: 300px;
        // height: 100%;
        background: #ffffff;
        .right-title {
          margin-bottom: 20px;
          font-size: 18px;
          color: #01142e;
        }
        .course-list {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          .course-item {
            display: flex;
            flex-direction: column;
            // height: 86px;
            row-gap: 12px;
            cursor: pointer;
            margin-bottom: 20px;
            .item-img {
              margin-right: 12px;
              width: 128px;
              height: 100%;
              border-radius: 3px;
              overflow: hidden;
            }
            .item-title {
              margin-bottom: 4px;
              font-size: 14px;
              font-weight: 400;
              color: #01142e;
              &.active {
                color: #004495;
              }
            }
            .item-status {
              font-size: 12px;
              color: #004495;
            }
            .item-time {
              font-size: 12px;
              font-weight: 400;
              color: #b2b2b2;
            }
          }
        }
      }
    }
  }
}
