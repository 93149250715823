.question-container {
  min-height: calc(100vh - 40px - 100px - 200px);
  padding: 20px 0 40px;
  .ant-space {
    width: 100%;
  }
  &-right {
    .right-title {
      padding-bottom: 8px;
      font-size: 18px;
      color: #000000;
      border-bottom: 2px solid #004495;
    }
    .question-item {
      display: flex;
      column-gap: 12px;
      width: 100%;
      background: #ffffff;
      font-weight: bold;
      cursor: pointer;
      .question-prefix {
        width: 23px;
        height: 23px;
      }
      .question-suffix {
        width: 18px;
        height: 10px;
      }
      .question-content {
        flex: 1;
        width: 100%;
        color: rgba($color: #000000, $alpha: 0.8);
      }
    }
    .question-collapse.ant-collapse {
      .ant-collapse-content {
        overflow: hidden;
        .ant-collapse-content-box {
          // padding: 0;
          overflow: hidden;
        }
      }
    }
    .step-wrap {
      .step-item {
        display: flex;
        align-items: center;
        background-color: #eff2f7;
        padding: 24px;
        border-radius: 4px;
        .step-title {
          font-size: 18px;
          font-weight: 700;
          color: #004495;
        }
        .step-content {
          line-height: 1.5;
        }
        .step-img {
          width: 118px;
        }
      }
      .step-arrow {
        width: 23px;
      }
    }
  }
}
