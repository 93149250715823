.course-list {
  margin-top: 20px;
  margin-bottom: 60px;
  position: relative;
  .loading-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  .empty-wrap {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}