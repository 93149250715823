.goods-detail-modal {
  .modal-wrap {
    display: flex;
    column-gap: 20px;
    min-height: 374px;
    .left-wrap {
      width: 420px;
      // height: 420px;
      img {
        height: 100%;
        height: auto !important;
      }
    }
    .right-wrap {
      width: 400px;
      .goods-title {
        margin-bottom: 12px;
        font-size: 16px;
        font-family: PingFang SC;
        // font-weight: bold;
        color: #000000;
      }
      .gods-brief {
        
      }
    }
  }
}
