.mall-container {
  // padding-top: 24px;

  .mall-header {
    display: flex;
    align-items: center;
    column-gap: 33px;
    height: 320px;

    &-left {
      width: 240px;
      height: 320px;
      border-radius: 12px;
      overflow: hidden;
    }

    &-right {
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .banner-list {
        display: flex;
        align-items: center;
        column-gap: 36px;

        .banner-item {
          width: calc((100% - 36px * 3) / 4);
          height: 140px;
          border-radius: 12px;
          overflow: hidden;
          cursor: pointer;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  .mall-category {
    margin-top: 25px;
    display: flex;
    column-gap: 24px;

    .mall-left {
      width: 220px;
    }

    .mall-right {
      flex: 1;
    }
  }

  .mall-header-left {
    &:hover {
      transform: scale(1.1);
    }
  }

  .carousel-item {
    width: 100%;
    height: 320px;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;

    img {
      border-radius: 12px;
      overflow: hidden;
    }
  }

  .main-banner-wrap {
    height: 430px;
    display: flex;
    // align-items: center;
    justify-content: space-between;

    .banner-left {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .banner-right-item {
        width: 219px;
        height: 130px;
        border-radius: 12px;
        overflow: hidden;
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .banner-right {
      height: 100%;
      width: 960px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .ant-carousel {
        &:hover {
          transform: scale(1.03);
        }
      }

      .carousel-item {
        width: 100%;
        height: 280px;
        border-radius: 12px;
        overflow: hidden;
        cursor: pointer;

        img {
          border-radius: 12px;
          overflow: hidden;
        }
      }

      .banner-left {
        flex-direction: row;
        column-gap: 20px;

        .banner-right-item {
          width: 320px;
          height: 130px;
          border-radius: 12px;
          overflow: hidden;
          cursor: pointer;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .search-wrap {
    margin: 25px 0 25px;
    display: flex;
    align-items: center;
    // column-gap: 20px;
    justify-content: space-between;
  }
}

