.notice-wrap {
  display: flex;
  padding: 20px;
  width: 100%;
  height: 160px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  .notice-left {
    margin-right: 22px;
    font-size: 18px;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    color: #004495;
  }
  .notice-right {
    overflow: hidden;
    flex: 1;
    width: 100%;
    .slick-slider {
      width: 100%;
    }
    .notice-item {
      width: 560px;
      height: 48px;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      cursor: pointer;
      &:hover {
        .ant-typography {
          color: #004495;
        }
      }
    }
  }
  .notice-work-title{
    padding-bottom: 7px;
    padding-right: 30px;
    margin-right: 13px;
    font-weight: bold;
    font-size: 18px;
    border-bottom: 2px solid #004495;
  }
  .notice-work-desc{
    color: rgb(0, 68, 149);
    font-size: 16px;    
  }
  .notice-work-desc>div{
    padding: 5px 0px;
    font-size: 14px;
    color: black;
  }
}
