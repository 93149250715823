.header-wrap {
  .nav-wrap {
    width: 100%;
    height: 40px;
    background-color: $primary;
    .nav-list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 40px;
      margin: auto;
      width: 1200px;
      height: 100%;
      .nav-item {
        display: flex;
        align-items: center;
        column-gap: 2px;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        img {
          width: 16px;
        }
        &:hover {
          // color: #004495;
          transform: scale(1.25);
        }
      }
    }
  }
}
.consult-modal {
  .custom-form {
    margin-top: 24px;
    display: flex;
    align-items: center;
    .form-item {
      padding-left: 27px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC;
      color: #01142e;
    }
    .label-item {
      width: 166px;
      height: 52px;
      background: #f9f9f9;
      border: 1px solid #d6d6d6;
      border-radius: 9px 0px 0px 9px;
      border-right: none;
    }
    .value-item {
      flex: 1;
      width: 100%;
      padding-left: 0;
      .ant-input {
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
    .surname-item {
      width: 241px;
      height: 52px;
      background: #ffffff;
      border: 1px solid #d6d6d6;
      border-radius: 0px 4px 4px 0px;
    }
  }
  .custom-form-text-area {
    margin-top: 24px;
    display: flex;
    align-items: center;
    border: 1px solid #d6d6d6;
    .form-item {
      width: 206px;
      padding-left: 27px;
      display: flex;
      align-items: center;
      height: 186px;
      background: #f9f9f9;
      font-size: 14px;
      font-family: PingFang SC;
      color: #01142e;
      border-right: 1px solid #d6d6d6;
    }
    .ant-input {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}
