.goods-table {
  .ant-table-cell {
    font-size: 14px;
    background-color: #ffffff !important;
  }
  .goods-img {
    width: 70px;
    height: 70px;
  }
  .ant-statistic {
    .ant-statistic-content {
      .ant-statistic-content-prefix {
        margin-inline-end: 0;
      }
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }
  }
}
.shopping-card {
  position: sticky;
  bottom: 0;
  padding: 0 30px 0 20px;
  width: 100%;
  height: 53px;
  background: #ffffff;
  box-shadow: -2px 0px 9px rgba(0, 0, 0, 0.16);
  column-gap: 30px;
  .shopping-content {
    flex: 1;
  }
}
