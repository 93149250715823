header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #d5d5d5;
}
.login-container {
  padding-top: 40px;
  position: relative;
  min-height: calc(100vh - 100px - 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
  .top-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 40px;
    cursor: pointer;
    .top-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 50px;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #004495;
      font-size: 16px;
      font-weight: 400;
      color: #004495
      a {
        color: #004495
      }
    }
  }
  .login-title {
    margin-bottom: 26px;
    text-align: center;
    font-size: 39px;
    font-weight: bold;
    color: #01142e;
  }
  .login-card {
    margin-bottom: 20px;
    padding: 24px;
    width: 818px;
    background: #f9f9f9;
    border-radius: 9px;
    overflow: hidden;
    .login-text {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 30px;
      color: #01142e;
    }
    .login-rule {
      margin-bottom: 30px;
      .rule-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        color: #01142e;
      }
      .rule-text {
        font-size: 14px;
        line-height: 25px;
        color: #828282;
      }
    }
  }
  .form-wrap {
    width: 818px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .from-item {
      display: flex;
      height: 60px;
      border: 1px solid #d6d6d6;
      border-radius: 10px;
      overflow: hidden;
      .item-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px 0 35px;
        width: 250px;
        height: 100%;
        background: #f9f9f9;
        border-right: 1px solid #d6d6d6;
        font-size: 16px;
        font-weight: 400;
        color: #01142e;
        .required-text {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 19px;
          background: #ff0000;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .item-right {
        flex: 1;
        width: 100%;
        height: 100%;
        input,
        .ant-input-password {
          padding: 4px 30px;
          height: 100%;
          border: none;
          outline: none;
          box-shadow: none;
          font-size: 16px;
          font-weight: 400;
        }
        .ant-input-password{
          & > input {
            padding-left: 0;
          }
        }
        .ant-select {
          height: 100%;
          width: 100%;
          .ant-select-selector {
            height: 100%;
            width: 100%;
            border: none;
            box-shadow: none;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
    .from-desc {
      padding-left: 274px;
      font-size: 16px;
      font-weight: 400;
      color: #b2b2b2;
    }
  }
  .forget-btn {
    padding-left: 440px;
    width: 1200px;
    margin-top: 8px;
    color: #004495;
    cursor: pointer;
  }
}
