.main-nav-wrap {
  height: 100px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .nav-left {
    width: 200px;
    cursor: pointer;
  }
  .nav-right {
    display: flex;
    align-items: center;
    column-gap: 20px;
    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 2px;
      padding: 0 4px;
      min-width: 130px;
      height: 40px;
      font-size: 16px;
      font-weight: 400;
      color: #606060;
      cursor: pointer;
      &.active,
      &:hover {
        background: #004495;
        border-radius: 4px;
        color: #ffffff;
        .nav-icon {
          color: #ffffff;
          display: none;
          &.white {
            display: block;
          }
        }
        .ant-badge,
        a {
          color: #ffffff;
        }
      }
      a {
        color: #606060;
      }
      .nav-icon {
        width: 20px;
        display: block;
        &.white {
          display: none;
        }
      }
    }
  }
}
