.acquisition-process-container {
  min-height: calc(100vh - 40px - 100px - 200px);
  padding: 20px 0 40px;
  .ant-space {
    width: 100%;
  }
  &-right {
    line-height: 1.5;
    .right-title {
      padding-bottom: 8px;
      font-size: 18px;
      color: #000000;
      border-bottom: 2px solid #004495;
    }
    .sub-title {
      font-size: 16px;
      font-weight: 700;
    }
    .form-table {
      border: 1px solid #ccc;
      border-bottom: none;
      .form-item {
        display: flex;
        position: relative;
        border-bottom: 1px solid #ccc;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 50%;
          bottom: 0;
          width: 1px;
          background-color: #ccc;
        }
        .form-item-left {
          padding: 0 20px;
          flex: 1;
          display: flex;
          align-items: center;
          background-color: #f1f1f1;
        }
        .form-item-right {
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          row-gap: 12px;
          flex: 1;
        }
      }
    }
    .step-wrap {
      .frame-space {
        &.ant-space,
        .ant-space-item {
          width: 100%;
        }
        &:hover {
          transform: scale(1.1);
        }
      }
      .step-item {
        display: flex;
        align-items: center;
        background-color: #eff2f7;
        padding: 24px;
        border-radius: 4px;
        .step-title {
          font-size: 18px;
          font-weight: 700;
          color: #004495;
        }
        .step-content {
          line-height: 1.5;
        }
        .step-img {
          width: 118px;
        }
      }
      .step-arrow {
        width: 23px;
      }
      .frame-wrap {
        width: 100%;
        height: 152px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 12px;
        padding: 0 22px;
        border: 1px solid #dbdbdb;
        .frame-title {
          font-weight: 600;
          color: #0a327a;
          font-size: 16px;
        }
        .frame-btn {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 60px;
          background-color: #0a327a;
          font-size: 20px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
  .sub-title {
    font-size: 16px;
    font-weight: 700;
  }
}
