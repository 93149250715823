.footer-wrap {
  .nav-wrap {
    width: 100%;
    height: 200px;
    background-color: $primary;
    .nav-container {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 40px;
      width: 1200px;
      height: 200px;
      .nav-list-wrap {
        flex: 1;
        display: flex;
        justify-content: space-between;
        height: 150px;
        padding: 20px 0;
      }
      .nav-list {
        margin: 0;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: space-between;
        column-gap: 80px;
        height: 100%;
        .nav-item {
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          &.no-hover {
            transform: scale(1) !important;
            color: #ffffff !important;
          }
          &:hover {
            transform: scale(1.2);
            color: #004495;
          }
          &.text {
            cursor: default;
          }
        }
      }
      .logo-wrap {
        // width: 200px;
        cursor: pointer;
      }
      .nav-footer {
        &:hover{
          opacity: 0.8;
          cursor: pointer;
        }
        img {
          width: 243px;
        }
      }
    }
  }
}
