.shopping-table-wrap {
  min-height: calc(100vh - 200px - 100px - 40px);
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  .container-wrap {
    padding: 35px 0;
    flex: 1;
    height: 100%;
    &.isEmpty {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .category-header {
      height: 44px;
      background: #ffffff;
    }
    .ant-table {
      .ant-table-thead,
      .ant-table-tbody {
        tr {
          .ant-table-cell {
            &:nth-child(1) {
              padding-left: 23px;
            }
          }
        }
      }
      .ant-table-thead {
        tr {
          .ant-table-cell {
            &::before {
              display: none;
            }
            font-size: 12px;
            font-weight: 400;
            color: #000000;
            background: #f5f5f5;
          }
        }
      }
      .ant-table-tbody {
        tr {
          .ant-table-cell {
            background-color: #fff !important;
          }
        }
      }
      .delete-btn {
        &:hover {
          transform: scale(1.2);
          color: #FF4D4F;
        }
      }
    }
    .goods-img {
      width: 70px;
      height: 70px;
    }
    .goods-info {
      height: 70px;
      .goods-title {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
      }
      .goods-desc {
        font-size: 12px;
        font-family: PingFang SC;
        color: #000000;
      }
      .goods-color {
        font-size: 12px;
        color: #828282;
      }
    }
    .ant-statistic {
      &.cell-statistic {
        &.primary {
          .ant-statistic-content-prefix,
          .ant-statistic-content-value-int,
          .ant-statistic-content-value-decimal {
            color: #004495;
          }
        }
        .ant-statistic-content,
        .ant-statistic-content-value {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .ant-statistic-content-prefix,
        .ant-statistic-content-value-int,
        .ant-statistic-content-value-decimal {
          font-size: 14px;
          color: #000000;
          font-family: PingFang SC;
        }
      }
    }
  }
  .footer-wrap {
    width: 100%;
    height: 53px;
    background: #ffffff;
    box-shadow: -2px 0px 9px rgba(0, 0, 0, 0.16);
    .footer-content {
      height: 100%;
      .delete-text {
        // font-size: 14px;
        // color: #b2b2b2;
        // padding: 0;
      }
      .normal-text {
        font-size: 12px;
      }
      .ant-statistic {
        &.total-statistic {
          .ant-statistic-content-prefix,
          .ant-statistic-content-value-int {
            font-size: 20px;
            color: #004495;
          }
        }
        .ant-statistic-content-value-int {
          color: #004495;
        }
      }
    }
  }
  .custom-checkbox {
    .ant-checkbox {
      margin-right: 16px;
    }
  }
  .left-24 {
    padding-left: 23px;
  }
}
