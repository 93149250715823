.settlement-container {
  min-height: calc(100vh - 200px - 100px - 40px);
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  .notice-header {
    width: 100%;
    height: 44px;
    background: rgba(230, 125, 4, 0.1);
    color: #ff9800;
  }
  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 42px 0 52px;
    row-gap: 20px;
    .ant-table {
      .ant-table-thead,
      .ant-table-tbody {
        tr {
          .ant-table-cell {
            &:nth-child(1) {
              padding-left: 23px;
            }
          }
        }
      }
      .ant-table-thead {
        tr {
          .ant-table-cell {
            &::before {
              display: none;
            }
            font-size: 12px;
            font-weight: 400;
            color: #000000;
            background: #f5f5f5;
          }
        }
      }
      .ant-table-tbody {
        tr {
          .ant-table-cell {
            background-color: #fff !important;
          }
        }
      }
      .ant-table-footer {
        background-color: #fff;
      }
      .empty-cell {
        border-bottom: none;
      }
      .goods-info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .goods-title {
          font-size: 14px;
          font-weight: bold;
          color: #000000;
        }
        .goods-text {
          font-size: 12px;
          color: #000000;
        }
        .goods-color {
          justify-self: flex-end;
          font-size: 12px;
          font-weight: 400;
          color: #828282;
        }
      }
    }
    .ant-statistic {
      &.cell-statistic {
        &.primary {
          .ant-statistic-content-prefix,
          .ant-statistic-content-value-int,
          .ant-statistic-content-value-decimal {
            color: #004495;
          }
        }
        .ant-statistic-content,
        .ant-statistic-content-value {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .ant-statistic-content-prefix,
        .ant-statistic-content-value-int,
        .ant-statistic-content-value-decimal {
          font-size: 14px;
          color: #000000;
          font-family: PingFang SC;
        }
      }
    }
    .discount-cell {
      padding: 0 15px 0;
      .form-item {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .glod-text {
          font-size: 12px;
          color: #e67d04;
        }
        .disabled-count {
          transform: translateX(6px);
          padding-right: 6px;
          width: 70px;
          height: 21px;
          background: #fbfbfb;
          border-radius: 4px;
          .ant-statistic {
            &.cell-statistic {
              .ant-statistic-content,
              .ant-statistic-content-value {
                display: flex;
                align-items: center;
                justify-content: flex-end;
              }
              .ant-statistic-content-prefix,
              .ant-statistic-content-value-int,
              .ant-statistic-content-value-decimal {
                font-size: 14px;
                color: #000000;
                font-family: PingFang SC;
              }
            }
          }
        }
      }
    }
    .footer-cell {
      padding-left: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cell-statistic {
        &.primary {
          .ant-statistic-content-prefix,
          .ant-statistic-content-value-int,
          .ant-statistic-content-value-decimal {
            color: #004495;
            font-size: 16px;
          }
        }
      }
    }
    .delivery-form-item {
      .item-title {
        display: flex;
        align-items: center;
        column-gap: 4px;
        margin-bottom: 24px;
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
        img {
          width: 48px;
        }
      }
      .item-remark-wrap {
        padding: 20px 30px 24px;
        height: 228px;
        background: #f9f9f9;
        border: 1px solid #d6d6d6;
        border-radius: 9px;
        .remark-title {
          margin-bottom: 12px;
          font-size: 14px;
          font-weight: 400;
          color: #01142e;
        }
      }
      .item-list {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        .item-wrap {
          padding: 0 20px 0 24px;
          height: 70px;
          // background: #f9f9f9;
          font-size: 14px;
          font-weight: 400;
          color: #01142e;
          cursor: pointer;
          &.active {
            background: rgba(0, 68, 149, 0.06);
            color: #004495;
            .checked-icon {
              width: 18px;
              height: 18px;
              background: #004495;
              border-radius: 50%;
              color: #ffffff;
              font-size: 10px;
            }
          }
          .checked-icon {
            width: 18px;
            height: 18px;
            background: #d6d6d6;
            border-radius: 50%;
            color: #ffffff;
          }
        }
        .item-card {
          &:hover {
            background: rgba(0, 68, 149, 0.06) !important;
            color: #004495 !important;
          }
        }
      }
    }
  }
  .footer {
    text-align: center;
    margin-top: 108px;
  }
  .offline-payment {
    // padding-left: 2em;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  .check-wrap {
    &:hover {
      transform: scale(1.1);
      color: #004495;
    }
  }
}
