.page-width {
  width: 1200px;
  margin: auto;
}
.logo-img {
  width: 240px;
  height: auto;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}
.flex-align {
  display: flex;
  align-items: center;
}
.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.align-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.column-between{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hover-scale {
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}
