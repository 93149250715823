.delivery-address,
.address-dialog {
  .header-wrap {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 28px;
      font-weight: 400;
      color: #000000;
    }
  }
  .loading-wrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  .address-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    .address-item {
      height: 130px;
      position: relative;
      margin-top: 24px;
      // height: 108px;
      background: #ffffff;
      border-radius: 4px;
      line-height: 20px;
      // font-size: 8px;
      font-weight: 400;
      color: #01142e;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
      &.there-count {
        width: calc((100% - 24px * 2) / 3);
      }
      &.four-count {
        width: calc((100% - 24px * 3) / 4);
      }
      // &.active {
      //   border: 1px solid #004495;
      // }
      .item-container {
        position: absolute;
        left: 0;
        top: 0;
        padding: 20px;
        width: 100%;
        height: 100%;
      }
      .item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .checked-icon {
          width: 14px;
          height: 14px;
          background: #004495;
          border-radius: 50%;
          color: #ffffff;
          font-size: 8px;
        }
        .item-btn-list {
          position: relative;
          display: flex;
          align-items: center;
          column-gap: 5px;
          .btn {
            cursor: pointer;
          }
        }
      }
      .split-line {
        margin: 6px 0 10px;
        width: 100%;
        height: 1px;
        background-color: #e0e0e0;
      }
    }
  }
  .pagination-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .form-wrap {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    .from-item {
      display: flex;
      height: 60px;
      border: 1px solid #d6d6d6;
      border-radius: 8px;
      overflow: hidden;
      .item-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px 0 35px;
        width: 250px;
        height: 100%;
        background: #f9f9f9;
        border-right: 1px solid #d6d6d6;
        font-size: 12px;
        font-weight: 400;
        color: #01142e;
        .required-text {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 19px;
          background: #ff0000;
          font-size: 8px;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .item-right {
        flex: 1;
        width: 100%;
        height: 100%;
        input {
          padding: 4px 30px;
          height: 100%;
          border: none;
          outline: none;
          box-shadow: none;
          font-size: 12px;
          font-weight: 400;
        }
        .ant-select {
          height: 100%;
          width: 100%;
          .ant-select-selector {
            display: flex;
            align-items: center;
            padding-left: 32px;
            height: 100%;
            width: 100%;
            border: none;
            box-shadow: none;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
    .from-desc {
      padding-left: 274px;
      font-size: 12px;
      font-weight: 400;
      color: #b2b2b2;
    }
  }
  .agree-wrap {
    margin: 18px 0 40px;
  }
}
.select-option-item {
  .ant-select-item {
    padding-left: 32px;
  }
}
