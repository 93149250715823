.video-detail {
  &-container {
    min-height: calc(100vh - 100px - 200px);
    .detail-info {
      background: #f2f2f2;
      .detail-info-wrap {
        height: 334px;
        display: flex;
        align-items: center;
        .info-left {
          margin-right: 50px;
          width: 402px;
          height: 268px;
          overflow: hidden;
          border-radius: 10px;
        }
        .info-right {
          height: 268px;
          padding: 6px 0 11px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .info-title {
            font-size: 24px;
            font-weight: bold;
            color: #000000;
          }
          .info-subtitle {
            margin-bottom: 25px;
            display: flex;
            align-items: baseline;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
          }
          .info-item {
            margin-bottom: 6px;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            color: #828282;
          }
          .info-item-left {
            width: 110px;
            margin-right: 18px;
          }
          .info-item-unit {
            font-size: 14px;
            font-weight: bold;
            color: #ff1e1e;
          }
          .info-item-price {
            font-size: 30px;
            font-weight: bold;
            color: #ff1e1e;
          }
        }
        .info-btn {
          display: flex;
          align-items: center;
          column-gap: 20px;
          .info-brdge {
            position: relative;
            .brdge-wrap {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: -10px;
              right: -2px;
              width: 66px;
              height: 22px;
              background: #ff961e;
            }
          }
        }
      }
    }
    .detail-wrap {
      background: #f8f8f8;
      padding: 40px 0;
      .detail-box {
        display: flex;
        justify-content: space-between;
        .detail-left {
          padding: 4px 32px 20px;
          width: 844px;
          background: #ffffff;
          .ant-tabs-nav {
            .ant-tabs-tab {
              padding: 12px 4px;
              &:hover {
                transform: scale(1.2);
              }
            }
            &::before {
              border-bottom: none;
            }
          }
        }
        .detail-right {
          width: 316px;
          height: 500px;
          background: #ffffff;
          .recommend-card {
            padding: 22px;
            .recommend-title {
              margin-bottom: 22px;
              font-size: 16px;
              color: #01142e;
            }
            .recommend-list {
              .recommend-item {
                margin-bottom: 32px;
                font-size: 14px;
                font-weight: bold;
                color: #000000;
                cursor: pointer;
                &:hover {
                  transform: scale(1.1);
                }
                img {
                  margin-bottom: 16px;
                  width: 100%;
                  height: 149px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.course-buy-modal {
  .modal-wrap {
    padding: 20px 0 70px;
    .modal-header {
      margin-bottom: 60px;
      display: flex;
      align-items: center;
      column-gap: 34px;
      height: 170px;
      .course-img {
        width: 260px;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
      }
      .header-right {
        height: 100%;
        .course-title {
          margin-bottom: 30px;
          font-size: 20px;
          font-weight: bold;
          color: #000000;
        }
        .course-info {
          font-size: 16px;
          font-weight: 400;
          color: #828282;
          .info-item {
            display: flex;
            .item-label {
              width: 70px;
            }
          }
        }
      }
    }
    .notice-title {
      margin-bottom: 18px;
      font-size: 20px;
      font-weight: bold;
      color: #000000;
    }
    .notice-text {
      font-size: 14px;
      font-weight: 400;
      color: #01142e;
    }
  }
  .modal-footer {
    padding: 28px 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e0e0e0;
    .footer-left {
      display: flex;
      align-items: baseline;
      .footer-text {
        margin-right: 12px;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
      }
      .footer-unit {
        font-size: 14px;
        font-weight: bold;
        color: #ff1e1e;
      }
      .footer-price {
        font-size: 30px;
        font-weight: bold;
        color: #ff1e1e;
      }
    }
  }
}
