@import '../../styles/variables';
.register-page-wrap {
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #d5d5d5;
  }
  .container {
    padding: 60px 0;
    width: 100%;
    min-height: calc(100vh - 220px);
    // margin-bottom: 148px;
    .container-wrap {
      margin: auto;
      .top-wrap {
        margin: 0 0 42px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        cursor: pointer;
        .top-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 205px;
          height: 60px;
          background: #ffffff;
          border-radius: 10px;
          border: 1px solid $primary;
          font-size: 16px;
          font-weight: 400;
          color: $primary;
          a {
            color: $primary;
          }
        }
      }
      .register-title {
        margin-bottom: 20px;
        font-size: 44px;
        font-weight: bold;
        color: #01142e;
        text-align: center;
      }
      .not-validate {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 818px;
        row-gap: 32px;
        margin: 40px auto 0;
        .register-card {
          width: 100%;
          padding: 24px 24px;
          color: #f44336;
          font-size: 14px;
          font-weight: 700;
          border-radius: 10px;
          overflow: hidden;
          background-color: #feedeb;
        }
      }

      .register-subtitle {
        margin-bottom: 80px;
        font-size: 18px;
        font-weight: 400;
        color: #01142e;
        text-align: center;
      }
      .form-wrap {
        padding-right: 140px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        .from-item {
          display: flex;
          height: 60px;
          border: 1px solid #d6d6d6;
          border-radius: 10px;
          overflow: hidden;
          .item-left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 4px;
            padding: 0 12px 0 35px;
            width: 250px;
            height: 100%;
            background: #f9f9f9;
            border-right: 1px solid #d6d6d6;
            font-size: 16px;
            font-weight: 400;
            color: #01142e;
            span {
              &:nth-child(1){
                flex: 1;
              }
            }
            .required-text {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px !important;
              height: 19px;
              background: #ff0000;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .item-right {
            flex: 1;
            width: 100%;
            height: 100%;
            input,
            .ant-input-password {
              padding: 4px 30px;
              height: 100%;
              border: none;
              outline: none;
              box-shadow: none;
              font-size: 16px;
              font-weight: 400;
            }
            .ant-input-password {
              & > input {
                padding-left: 0;
              }
            }
            .ant-select {
              height: 100%;
              width: 100%;
              .ant-select-selector {
                display: flex;
                align-items: center;
                padding: 4px 30px;
                height: 100%;
                width: 100%;
                border: none;
                box-shadow: none;
                font-size: 16px;
                font-weight: 400;
              }
            }
          }
        }
        .from-desc {
          padding-left: 274px;
          font-size: 16px;
          font-weight: 400;
          color: #b2b2b2;
        }
      }
      .register-max-title {
        margin: 100px 0 64px;
        font-size: 44px;
        font-weight: bold;
        color: #01142e;
        text-align: center;
      }
      .form-footer {
        margin-top: 70px;
        text-align: center;
        .form-btn {
          width: 300px;
          height: 60px;
          border-radius: 10px;
        }
      }
    }
  }
}
