.category-card {
  width: 100%;
  flex: 1;
  .indent-sub-title {
    padding-left: 6px;
  }
  .category-title {
    margin-bottom: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
  }
  .category-sub-title {
    margin: 30px 0;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #01142e;
  }
  .category-model {
    margin-bottom: 8px;
    display: flex;
    .category-model-left {
      width: 150px;
      font-size: 14px;
      font-weight: 400;
      color: #b2b2b2;
    }
    .category-model-right {
      transform: translateY(-4px);
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 50px;
      .model-item {
        margin: 0 0 16px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        &.ant-tag-checkable-checked{
          color: #fff;
          &:hover {
            transform: scale(1.2);
            color: #fff;
            background-color: #004495 !important;
          }
        }
        &:hover {
          transform: scale(1.2);
          color: #fff;
          background-color: #666 !important;
        }
      }
    }
  }
  .indent-title {
    padding-left: 13px;
  }
}
