.main-wrap {
  .main-container {
    margin: auto;
    height: 100%;
    .main-banner-wrap {
      height: 430px;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      .banner-left {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        .banner-right-item {
          width: 219px;
          height: 130px;
          border-radius: 12px;
          overflow: hidden;
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .banner-right {
        height: 100%;
        width: 960px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .ant-carousel {
          &:hover {
            transform: scale(1.03);
          }
        }
        .carousel-item {
          width: 100%;
          height: 280px;
          border-radius: 12px;
          overflow: hidden;
          cursor: pointer;
          img {
            border-radius: 12px;
            overflow: hidden;
          }
        }
        .banner-left {
          flex-direction: row;
          column-gap: 20px;
          .banner-right-item {
            width: 320px;
            height: 130px;
            border-radius: 12px;
            overflow: hidden;
            cursor: pointer;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    .search-wrap {
      margin: 40px 0 30px;
      display: flex;
      align-items: center;
      // column-gap: 20px;
      justify-content: space-between;
    }
  }
}
