.user-detail {
  padding: 65px 0;
  min-height: calc(100vh - 100px - 200px);
  border-top: 1px solid #d5d5d5;
  .avatar-wrap {
    position: relative;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
    .edit-btn {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 51px;
      height: 24px;
      background: #004495;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .user-info {
    padding: 12px 0;
    // height: 154px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .user-title {
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-size: 23px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 0px;
      color: #01142e;
      opacity: 1;
      .user-vip {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 2px;
        padding: 0 4px;
        height: 22px;
        background: #ff961e;
        border-radius: 10px 0px 10px 0px;
        font-size: 12px;
        font-weight: 400;
        color: #000000;
        .img-wrap {
          width: 15px;
          height: 12px;
        }
      }
    }
    .user-email {
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: normal;
      line-height: 20px;
      color: #01142e;
      opacity: 1;
    }
    .user-number-btn {
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .tab-list {
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    border-right: 1px solid #efefef;
    .tab-li {
      &:hover {
        transform: scale(1.2);
        .tab-item,
        .tab-sub-item {
          &.active {
            color: #ffffff !important;
          }
          color: #004495;
        }
      }
    }
    .tab-item {
      padding-left: 12px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      width: 223px;
      height: 50px;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 400;
      color: #01142e;
      cursor: pointer;
      &.active {
        background: #004495;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
      }
      
      .icon {
        width: 22px;
        height: 22px;
        // background: #004495;
      }
    }
    .tab-sub-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 223px;
      height: 50px;
      border-radius: 10px;
      font-size: 18px;
      font-weight: 400;
      color: #01142e;
      cursor: pointer;
      &.active {
        background: #004495;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .nav-tab-content {
    .ant-tabs-tab {
      padding: 12px 4px;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .ant-table {
    .ant-table-thead {
      .ant-table-cell {
        font-size: 14px;
        color: #b2b2b2;
        font-family: PingFang SC !important;
        background-color: #ffffff !important;
        &::before {
          display: none !important;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        background-color: #ffffff !important;
      }
    }
  }
  .ant-tabs {
    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
  .operate-btn-list {
    .ant-btn {
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
