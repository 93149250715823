.course-buy-type-modal {
  .modal-wrap {
    padding-top: 20px;
    min-height: 120px;
    .classify-list {
      .classify-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 74px;
        font-size: 20px;
        font-weight: 400;
        color: #01142e;
        background: #ffffff;
        border: 1px solid #d6d6d6;
        border-radius: 10px;
        &.active,
        &:hover {
          border: 1px solid #004495;
        }
      }
    }
    .offline-payment {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      // padding: 20px;
      // height: 200px;
      background: #ffffff;
      // border: 1px solid #004495;
      // border-radius: 10px;
      .title {
        margin-bottom: 13px;
        font-size: 20px;
        font-weight: bold;
        color: #004495;
      }
      .company {
        font-size: 14px;
        font-weight: 400;
        color: #01142e;
      }
      .desc {
        font-size: 14px;
        font-weight: 400;
        color: #fe0031;
      }
    }
  }
}