.equipment-category-wrap {
  display: flex;
  margin-bottom: 94px;
  .category-left {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    .category-item {
      display: flex;
      align-items: center;
      padding-left: 20px;
      width: 220px;
      height: 70px;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      font-size: 16px;
      color: #01142e;
      cursor: pointer;
      &.active,
      &:hover {
        border-color: #004495;
        color: #004495;
        font-weight: 700;
      }
      .img-wrap {
        margin-right: 10px;
        width: 34px;
        height: 34px;
      }
    }
  }
  .category-right {
    flex: 1;
    width: 100%;
    .indent-title {
      padding-left: 13px;
    }
    .indent-sub-title {
      padding-left: 6px;
    }
    .category-sub-title {
      margin: 30px 0;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      color: #01142e;
    }
  }
}
