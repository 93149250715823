.contact-address {
  padding-left: 40px;
  .address-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 33px 35px;
    // height: 229px;
    background: #f9f9f9;
    border-radius: 10px;
    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 34px;
      color: #01142e;
    }
    .address-footer {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      .form-title {
        font-size: 18px;
        font-weight: 400;
        color: #01142e;
      }
      .form-item {
        display: flex;
        // flex-direction: column;
        // align-items: center;
        column-gap: 16px;
        .footer-label {
          padding-top: 8px;
          width: 140px;
          text-align: right;
          font-size: 16px;
        }
        .ant-input {
          flex: 1;
          width: 100%;
        }
      }
      .footer-label {
        margin-right: 8px;
      }
      .footer-text {
        color: #004495;
      }
    }
  }
}
