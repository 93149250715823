.my-course-list {
  position: relative;
  .loading-wrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .collect-course {
    padding-left: 40px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    &.empty {
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
    .course-item {
      display: flex;
      flex-direction: column;
      width: calc((100% - 40px - 40px) / 3);
      height: 294px;
      margin-top: 40px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 0 0 10px 10px;
      overflow: hidden;
      cursor: pointer;
      &:nth-of-type(-n + 3) {
        margin-top: 0;
      }
      &:hover {
        transform: scale(1.1);
        border: 1px solid #004495;
      }
      .img-wrap {
        position: relative;
        width: 100%;
        height: 180px;
        .play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
      .item-footer {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        .footer-title {
          width: 215px;
          height: 44px;
          font-size: 16px;
          font-weight: bold;
          color: #000000;
        }
        .footer-desc {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .footer-time {
          font-size: 13px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .pagination-wrap {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
}
