.line-page {
  .line-header {
    height: 80px;
    border-bottom: 3px solid #3b3b3b;
    .img-wrap {
      display: flex;
      align-items: center;
      margin: auto;
      height: 100%;
      width: 960px;
      img {
        width: 60px;
        height: 21px;
      }
    }
  }
  .line-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px auto 0;
    width: 960px;
    .img-wrap {
      border: #cdcdcd 1px solid;
      width: 250px;
      height: 250px;
    }
    .line-title {
      margin: 30px 0 16px;
      font-size: 18px;
      color: #444;
    }
    .line-text {
      font-size: 13px;
      color: #999;
      line-height: 1.6em;
    }
    .line-btn {
      margin-top: 24px;
      font-size: 14px;
      color: #00e;
      cursor: pointer;
    }
  }
}
