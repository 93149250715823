.business-hours {
  margin-top: 60px;
  &-title {
    padding-bottom: 15px;
    font-size: 18px;
    border-bottom: 1px solid #004495;
  }
  .calendar-list-wrap {
    transform: translate(-30px, -90px) scale(0.75);
    .calendar-wrap {
      position: relative;
      margin-top: 30px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 9;
        width: 300px;
        height: 100%;
      }
      .calendar-header {
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        font-size: 18px;
        color: #fff;
        background-color: #808080;
      }
      &.future {
        .ant-picker-calendar {
          .ant-picker-cell-selected {
            .ant-picker-calendar-date {
              background: none;
              color: rgba(0, 0, 0, 0.88);
              &::before {
                border: none !important;
              }
            }
          }
        }
      }
      .ant-picker-calendar {
        thead tr th,
        tbody tr td.ant-picker-cell-in-view {
          &:nth-child(1) {
            color: red;
          }
          &:nth-last-child(1){
            // color: #004495;
          }
        }
        .ant-picker-cell-today.ant-picker-cell-selected {
            // background: #f23e0c;
            // border-radius: 50%;
            // color: rgba(0, 0, 0, 0.88);
            // &::before {
            //   border: 1px solid #f23e0c;
            // }
        }
      }
    }
  }
  .business-hours-desc {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    font-size: 15px;
  }
  .line-img {
    margin-top: 20px;
    width: 100%;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
