.course-chapter {
  &-desc {
    font-size: 14px;
    font-weight: 400;
    color: #01142e;
  }
  &-title {
    font-size: 18px;
    font-weight: 400;
    color: #01142e;
  }
  &-tag {
    padding: 0 4px;
    // width: 53px;
    height: 23px;
    line-height: 23px;
    background: rgba(0, 68, 149, 0.1);
    border-radius: 6px;
    font-size: 12px;
    font-weight: 400;
    color: #004495;
    text-align: center;
  }
  .course-wrap {
    width: 100%;
    height: 720px;
    margin: 50px 0 16px;
    .blur-img {
      width: 100%;
      height: 100%;
    }
  }
  .course-detail {
    display: flex;
    align-items: center;
    column-gap: 18px;
    margin: 50px 0 16px;
  }
  .course-list {
    display: flex;
    flex-direction: column;
    .course-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 89px;
      padding: 0 30px 0 16px;
      border-radius: 4px;
      &.active {
        background: #fbfbfb;
      }
      .item-left {
        .item-header {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          .item-tag {
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49px;
            height: 23px;
            background: rgba(0, 68, 149, 0.2);
            border-radius: 12px;
            font-size: 14px;
            font-weight: 400;
            color: #004495;
          }
          .item-title {
            font-size: 18px;
            font-weight: 400;
            color: #01142e;
          }
        }
        .item-time {
          font-size: 12px;
          font-weight: 400;
          color: #01142e;
          &.active {
            font-size: 12px;
            font-weight: 400;
            color: #b2b2b2;
          }
        }
      }
      .item-right {
        width: 33px;
        height: 33px;
        border-radius: 10px;
      }
    }
  }
  .course-footer {
    margin-top: 35px;
    .footer-title {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 400;
      color: #01142e;
    }
    .footer-text {
      font-size: 14px;
      font-weight: 400;
      color: #828282;
    }
  }
}
