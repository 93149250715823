.goods-cell {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  .goods-header {
    height: 17px;
    display: flex;
    align-items: center;
    .pay-tag {
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 36px;
      padding: 6px;
      height: 17px;
      background: #004495;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .goods-container {
    display: flex;
    align-items: center;
    column-gap: 16px;
    height: 83px;
    .img-wrap {
      width: 83px;
      height: 100%;
    }
    .goods-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .goods-title {
        font-size: 14px;
        font-weight: bold;
        color: #000000;
      }
      .goods-text {
        font-size: 12px;
        color: #000000;
      }
      .goods-desc {
        justify-self: flex-end;
        font-size: 12px;
        font-weight: 400;
        color: #828282;
      }
    }
  }
}
.price-cell {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #004495;
  font-size: 14px;
  &-status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47px;
    height: 17px;
    background: rgba(0, 68, 149, 0.1);
    border-radius: 4px;
    font-size: 12px;
    color: #004495;
  }
}
.ant-statistic {
  &.cell-statistic {
    &.primary {
      .ant-statistic-content-prefix,
      .ant-statistic-content-value-int,
      .ant-statistic-content-value-decimal {
        color: #004495;
      }
    }
    .ant-statistic-content,
    .ant-statistic-content-value {
      display: flex;
      align-items: center;
    }
    .ant-statistic-content-prefix,
    .ant-statistic-content-value-int,
    .ant-statistic-content-value-decimal {
      font-size: 14px;
      color: #000000;
      font-family: PingFang SC;
    }
  }
}
