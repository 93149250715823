.search-list-wrap {
  min-height: calc(100vh - 220px);
  .search-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    background: #f8f8f8;
    .custom-search {
      display: flex;
      align-items: center;
      height: 50px;
      column-gap: 20px;
      .ant-input-search {
        height: 100% !important;
        .ant-input-wrapper,
        .ant-input-affix-wrapper,
        .ant-input-group-addon,
        .ant-btn {
          height: 100% !important;
        }
        .ant-input-affix-wrapper {
          box-shadow: none;
          border-radius: 10px 0 0 10px;
        }
        .ant-input-group-addon {
          .ant-btn {
            width: 100px;
            border-radius: 0 10px 10px 0;
          }
        }
      }
    }
  }
  .list-wrap {
    padding-top: 20px;
  }
}
