.course-card {
  display: flex;
  flex-direction: column;
  height: 288px;
  border: 1px solid #e0e0e0;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  &:hover {
    transform: scale(1.1);
    border: 1px solid #004495;
  }
  .img-wrap {
    position: relative;
    height: 134px;
    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  .item-detail {
    flex: 1;
    padding: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .badge {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: -1px;
      top: -8.5px;
      width: 59px;
      height: 17px;
      background: #ff961e;
      border-radius: 10px 0px 10px 0px;
      img {
        width: 10px;
        transform: translateX(4px);
      }
      &-text {
        font-size: 10px;
        font-weight: 400;
        color: #000000;
        transform: scale(0.75);
      }
    }
    .item-title {
      font-size: 14px;
      font-weight: bold;
      color: #000000;
    }
    .tag-list {
      margin-bottom: 12px;
      .tag-item {
        display: flex;
        align-items: center;
        // padding: 10px;
        height: 17px;
        background: rgba(0, 68, 149, 0.1);
        border-radius: 2px;
        font-size: 9px;
        font-weight: 400;
        color: #004495;
        span {
          transform: scale(0.75);
        }
      }
    }
    .item-date {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .item-price {
      margin-top: 8px;
      font-size: 18px;
      font-weight: bold;
      color: #ff0000;
    }
  }
}
