$red-active: #FF4D4F;
$primary-active: #004495;

.devie-type-tree {
  .devie-item {
    margin-bottom: 10px;
    cursor: pointer;
    &.active,
    &:hover {
      .item-title {
        color: $red-active;
      }
    }
    &:hover {
      transform: scale(1.1);
    }
    .item-title {
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      img {
        margin-right: 12px;
        width: 30px;
        height: 30px;
      }
    }
  }
  .devie-collapse {
    border: none;
    .ant-collapse-item {
      border: none;
      &:hover {
        transform: scale(1.1);
      }
      &.active,
      &:hover {
        .ant-collapse-header-text {
          color: $red-active;
          font-weight: 700;
        }
      }
      .ant-collapse-header {
        padding: 20px 16px 20px 0;
        background-color: #fff;
      }
      .ant-collapse-content {
        border: none;
        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
    .devie-type {
      padding-left: 20px;
      width: 100%;
      height: 44px;
      background: #fcfcfc;
      cursor: pointer;
      &:hover,
      &.active {
        color: $red-active;
        font-weight: 700;
      }
    }
  }
}
